.PreviousBtn {
    width: 3rem;
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 31px);
    left: 5rem;
  }
  
  .NextBtn {
    width: 3rem;
    position: absolute;
    top: calc(50% - 31px);
    transform: translateY(-50%);
    right: 5rem;
  }
  
  .form-check-input:checked {
    background-color: red;
    border-color: red;
}