#containerElement::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
  }
  
  /* Style the scrollbar thumb */
  #containerElement::-webkit-scrollbar-thumb {
    background-color: #151B47;
    border-radius: 50px; /* Add rounded corners */
  }
  
  /* Handle on hover */
  #containerElement::-webkit-scrollbar-thumb:hover {
    background-color: #151B47;
  }
  
  /* Handle on drag */
  #containerElement::-webkit-scrollbar-thumb:active {
    background-color: #151B47;
  }
  
  /* Define the scrollbar track */
  #containerElement::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* Define the scrollbar corner */
  #containerElement::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }
  