.image-container-bestSeller {
  width: 100%;
  /* Set your preferred width */
  position: relative;
  overflow: hidden;

  &:hover {
    .steeringFrame {
      visibility: visible;
    }
  }

}

.circleFrame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.steeringFrame {
  position: absolute;
  visibility: hidden;
  top: 80%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.carouseld-bestSeller {
  width: 25vh;
  height: 25vh;
  object-fit: cover;
  border-radius: 50%;
  padding: 2vh;
}

.card-bestSeller {
  position: relative;
  width: 30vh;
  text-align: center;
}

.content-bestSeller {
  text-align: center;
  padding: 1rem;
}

.location-bestSeller {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.location-bestSeller img {
  width: 2vh;
  margin-right: 1vh;
}

.location-bestSeller p {
  color: gray;
}

.thumbnail-button {
  color: #fff;
  border: none;
  cursor: pointer;
}


.selected {
  display: inline-block;
  /* Make the parent element's width fit its content */
  position: relative;
  /* Ensure relative positioning for the parent element */
}

.selected::after {
  content: '';
  /* Create a pseudo-element */
  position: absolute;
  /* Position the pseudo-element relative to its parent */
  left: 0;
  /* Start the line from the left edge of the parent element */
  bottom: -2px;
  /* Adjust the position to align with the bottom of the text */
  width: 100%;
  /* Make the line as wide as the parent element */
  border-bottom: 2px solid;
  /* Add a solid underline */
}

span {
  cursor: pointer;
}

  .province-hot2 {
  margin: 0 15px;
}

@media (max-width: 720px) {
  .content-bestSeller h3 {
    font-size: 16px;
  }

  .location-bestSeller p {
    font-size: 12px;
  }

  .circleFrame {
    width: 75%;
  }

  .steeringFrame {
    width: 40%;
  }

  .carouseld-bestSeller {
    width: 20vh;
    height: 20vh;
    padding: 1.5vh;
  }

  .card-bestSeller {
    width: 25vh;
    margin: 0 auto;
  }

  .location-bestSeller img {
    width: 1.5vh;
    margin-right: 0.5vh;
  }

  .content-bestSeller {
    padding: 0.5rem;
  }

  .province-hot2 {
  margin: 0 5px;
  font-size: 12px;
}
}