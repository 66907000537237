/* ExpandedBookingCard.css */

.expanded-box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
}

.expanded-box .card-body {
    padding: 20px;
}

.expanded-box .form-label {
    font-weight: bold;
}

.expanded-box .btn-edit {
    margin-top: 20px;
}

.stepper-space {
    margin-bottom: 2%;
}

.button-Edit {
    justify-items: left;
    align-items: left;
    margin: 10px 10px;
    width: 100px;
    border-radius: 8px !important;
    border: none !important;
    background-color: #b5b5b5 !important;
    color: #00305c !important;
    font-weight: bold !important;
}

.button-Save {
    justify-items: left;
    align-items: left;
    margin: 10px 10px;
    width: 100px;
    border-radius: 8px !important;
    border: none !important;
    background-color: #00305c !important;
    font-weight: bold !important
}

.button-Cancel {
    justify-items: left;
    align-items: left;
    margin: 10px 10px;
    width: 100px;
    border-radius: 8px !important;
    border: none !important;
    background-color: #b5b5b5 !important;
    color: #00305c !important;
    font-weight: bold !important;
}

.button-Deposit {
    justify-items: left;
    align-items: left;
    margin: 10px 10px;
    width: 200px;
    border-radius: 8px !important;
    border: none !important;
    background-color: #00305c !important;
    font-weight: bold !important
}

.button-Deposit:hover {
    background-color: #004f99 !important;
}

.button-Save:hover {
    background-color: #004f99 !important;
}

.button-Cancel:hover {
    background-color: #cecece !important;
}

.button-Edit:hover {
    background-color: #cecece !important;
}


.button-step1 {
    display: flex;
    justify-content: left;
}

.deposit-text {
    font-size: 14px !important;
}

.button-step2 {
    width: 150px;
    margin: 0 5px;
    border-radius: 8px !important;
    border: none !important;
    background-color: #00305c !important;
    font-weight: bold !important
}

.button-step2:hover {
    background-color: #004f99 !important;
}