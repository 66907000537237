/* customCheckbox.css */
.custom-checkbox {
    display: flex;
    align-items: center;
}

.custom-checkbox .form-check-input {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #151B47;
    border-radius: 4px;
    transition: background-color 0.3s, border-color 0.3s;
    margin-top: 4px;
}

.custom-checkbox .form-check-input:checked {
    background-color: #00305c;
    border-color: #00305c;

}

.custom-checkbox .form-check-input:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-checkbox .form-check-label {
    margin-left: 8px;
    margin-top: 4px;
}