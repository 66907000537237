.popup-content {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 250px;
  /* Set a fixed width */
  max-height: 70vh;
  /* Set a maximum height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 20px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.7);
  z-index: 1000;
  border-radius: 28px;
  right: 10;
  margin-top: 10px;
  touch-action: pan-y;
  /* Enable vertical swiping on touch devices */
  font-size: small;
  left: 150px;
}

.filter-button {
  background-color: #00305c;
  /* Dark blue color */
  color: white;
  border: none;
  border-radius: 24px;
  /* Round edges */
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  /* Position the button */
  left: 135px;
  /* Position it to the left side of the screen */
  top: 10px;
  /* Adjust top position as needed */
}

.btn:focus,
.btn:active {
  background-color: #1c3580;
  /* Lighter blue color when focused or active */
  outline: none;
  /* Remove default focus outline */
}