.navbarhome-a {
  position: absolute;
  top: 50px;
  left: 20px;
  right: 20px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarhome-a ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbarhome-a li {
  margin: 0 10px;
}



.background-image-a {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.menuSail-svg-a,
.lang-svg-a,
.profileUnLogin-svg-a {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
}

.logoSail-image-a {
  cursor: pointer;
  height: 6rem;
  width: 6rem;
  z-index: 3;
}

.container-a {
  position: relative;
  /* Change to relative to position child elements absolutely */
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.overlay-a {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 40%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  pointer-events: none;
  z-index: 2;
  /* Ensure it is on top of the background image */
}

.container-form-a {
  z-index: 2;
  position: absolute;
  top: 60%;
  left: 50%;
}

.profile-circle-a {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
}




@media screen and (max-width: 720px) {
  .background-image-a {
    height: 325px;
  }

  .navbarhome-a {
    top: 5px;
    left: 10px;
    right: 10px;
  }

  .navbarhome-a ul {
    flex-wrap: wrap;
  }

  .navbarhome-a li {
    margin: 10px 5px;
  }

  .menuSail-svg-a,
  .lang-svg-a {
    height: 2rem;
    width: 2rem;
  }

  .logoSail-image-a {
    height: 4rem;
    width: 4rem;
  }

  .container-form-a {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .profileUnLogin-svg-a {
    height: 2rem;
    width: 2rem;
  }

  .profile-circle-a {
    height: 2rem;
    width: 2rem;
  }

}