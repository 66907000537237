    .image-provice img {
        width: 100%;
        justify-content: center;
    }

    .ProvinceCard {
        margin-top: 2%;
    }

    .province-des {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .provice-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    @media screen and (max-width: 720px) {

        .province-des,
        .provice-detail {
            flex-direction: row;
            align-items: center;
        }

        .ProvinceCard {
            justify-content: center;
        }

        .image-provice img {
            justify-content: center;
            width: 90%;
            margin: 10px 10px;
        }

        .province-des {
            margin: 10px 25px;
            font-size: 10px;
        }

        .headline-text {
            font-size: 14;
        }
    }