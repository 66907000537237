.title-container {
    justify-content: flex-start;
}

.title-text {
    margin: 0 0.5rem;
}

@media (max-width: 720px) {
    .title-container {
        justify-content: center;
    }


}