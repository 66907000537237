.inputNavbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.navbar-collapse{
    display: flex;
    flex-direction: column;
}
.LogoNavbar{
    width: 10vh;
}