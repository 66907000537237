.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.footer-col {
    flex: 1;
    margin: 10px;
    min-width: 200px;
    /* Adjust based on your design */
}



@media (max-width: 767.98px) {

    /* sm and down */
    .row {
        flex-direction: column;
    }

    .footline p {
        font-size: 12px;
    }

    .footline p:nth-child(1) {
        order: 2;
    }

    .footline p:nth-child(2) {
        order: 1;
    }

}

@media (min-width: 768px) {

    /* md and up */
    .row {
        flex-direction: row;
    }
}


.Img-icon {
    display: inline-block;
    margin: 0 10px;
}