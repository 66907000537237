.yacht-card {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.75) !important;
    border: 0.125rem solid transparent !important;
    margin: 15px auto !important;
    width: 100%;
    height: 225px;
}

.image-container-yacht {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.image-container-yacht img {
    max-height: 300px;
    width: auto;
    object-fit: cover;
}

.headline-text {
    text-align: left;
    font-size: 36px !important;
    color: rgb(21, 21, 93) !important;
}

.destination1-text {
    text-align: left !important;
}

.destination2-text {
    text-align: left !important;
}

.destination3-text {
    text-align: left !important;
    font-size: 17 !important;
}

.destination4-text {
    text-align: left !important;
}

.destination5-text {
    text-align: left !important;
}

.price-text {
    font-size: 24px !important;
    font-weight: bold;
}

.thb-text {
    font-size: small !important;
    padding-right: 10%;
}

.start-from {
    font-size: smaller !important;
    transform: translateX(-0.3vw);
}

.price-all {
    margin: 15px 15px;
    align-items: end;
}

.day-text {
    margin-top: 2px;
    font-size: 24px !important;
}

.price-align {
    align-items: baseline;
    display: flex;
    transform: translateX(-1.2vw);
    margin-bottom: auto;
}

@media (max-width: 720px) {
    .yacht-card {
        width: 90%;
        height: 225px;
    }

    .headline-text {
        text-align: left;
        font-size: 18px !important;
        color: rgb(21, 21, 93) !important;
    }

    .destination1-text {
        text-align: left !important;
        font-size: 10px !important;
    }

    .destination2-text {
        text-align: left !important;
        font-size: 12px !important;
    }

    .destination3-text {
        text-align: left !important;
        font-size: 10px !important;
        margin-bottom: 5px !important;
    }

    .destination4-text {
        text-align: left !important;
        font-size: 8px !important;

    }


    .price-text {
        font-size: 18px !important;
        font-weight: bold;

    }

    .thb-text {
        font-size: small !important;
        font-size: 12px !important;
    }

    .start-from {
        visibility: hidden;
    }

    .day-text {
        font-size: 18px !important;
    }

    .price-align {
        display: inline;
    }
}