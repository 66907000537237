.navbarhome {
  position: absolute;
  top: 20px;
  left: 40px;
  right: 20px;
  z-index: 3;
  /* Ensure it is on top of the background image and text */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarhome ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbarhome li {
  margin: 10px 10px;
}


.lang-svg,
.menuSail-svg,
.profileUnLogin-svg {
  cursor: pointer;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  position: relative;
  /* Ensure it is behind the nav */
  overflow: hidden;
}

.menuSail-svg,
.lang-svg,
.profileUnLogin-svg {
  height: 3rem;
  width: 3rem;
}

.container {
  position: relative;
  /* Change to relative to position child elements absolutely */
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 40%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  pointer-events: none;
  z-index: 2;
  /* Ensure it is on top of the background image */
}

.shape-image,
.logoSail-image {
  position: absolute;
  top: -1%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 50%;
  z-index: 2;
}

.logoSail-image {
  top: 5%;
  max-width: 10%;
  z-index: 3;
}

.container-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  /* Center the text horizontally */
  z-index: 3;
}

.welcome-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 3vw;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 7px 5px rgba(183, 183, 183, 0.3), 0px -4px 10px rgba(58, 58, 58, 0.3);
  margin-bottom: 1rem;
  white-space: nowrap;
  /* Prevent text wrapping */

  /* Add some margin to separate the texts */
}

.yacht-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 10vh;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 7px 5px rgba(183, 183, 183, 0.3), 0px -4px 10px rgba(0, 51, 255, 0.3);
  margin-top: 0;
  /* Reset margin */
  margin-bottom: 0;
  /* Reset margin */
  white-space: nowrap;
  /* Prevent text wrapping */

}

.journey-a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  font-family: 'Ubuntu';
  font-size: 2vh;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 50%;
  z-index: 2;
}

.OrderGrid3 {
  order: 3;
}

.OrderGrid4 {
  order: 4;
}

.profile-circle {
  width: 45px;
  /* Adjust size as needed */
  height: 45px;
  /* Adjust size as needed */
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
}

@media screen and (max-width: 720px) {

  .OrderGrid3 {
    order: 4;
  }

  .OrderGrid4 {
    order: 3;
  }

  .container-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -170%);
    text-align: center;
    z-index: 3;
  }

  .navbarhome {
    left: 20px;
  }

  .welcome-text {
    font-size: 2rem;
    white-space: nowrap;
    /* Prevent text wrapping */

  }

  .yacht-text {
    font-size: 3rem;
    white-space: nowrap;
    /* Prevent text wrapping */

  }

  .journey-a {
    font-size: 0.5rem;
  }

  .menuSail-svg,
  .profileUnLogin-svg,
  .lang-svg {
    width: 2rem;
    height: 2rem;
    transform: translateY(50px);
  }

  .background-image {
    height: 700px;
  }

  .shape-image {
    max-width: 120%;
  }

  .logoSail-image {
    max-width: 20%;
  }

  .ant-picker-dropdown {
    width: 280px;
    height: 320px;
    transition: width 0.3s, height 0.3s;
  }

  .custom-range-picker {
    width: 100% !important;
    height: 8vh !important;
    margin-right: 1px !important;
    margin-left: 1px !important;
    margin-top: 3px !important;
    margin-bottom: 1px !important;
    border-radius: 40px !important;
  }
}


.container-form {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 60%;
  left: 50%;

}

.ant-picker-suffix .anticon-calendar,
.ant-picker-clear .anticon-close-circle {
  font-size: 20px;
}

.custom-range-picker {
  width: 100% !important;
  height: 6vh;
  margin-right: 1px !important;
  margin-left: 1px !important;
  margin-top: 3px !important;
  margin-bottom: 1px !important;
  border-radius: 40px !important;
}