@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../scss/homepages.scss";
@import "../scss/plugins.scss";
@import "./destination.scss";

$fontAll: "Noto Sans Thai", sans-serif;

body {
  h1,h2,h3,h4,h5,p,span {
    font-family: $fontAll;

  }
  h2,span,label{
    font-family: $fontAll;
    color: #151B47;
    
  }
  h1{
    font-weight: 400;
    color: #151B47;
  }
  h2{
    font-weight: 400;
    // font-size: 42px;
  }
  span{
    font-weight: 300;
  }
}
