/* Common styles */
.modal-nav-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.modal-close-button {
  position: fixed;
  top: 1rem;
  right: 2rem;
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.modal-dot-container {
  position: absolute;
  bottom: -2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
  cursor: pointer;
}

.modal-dot.active {
  background-color: #00305c;
}

/* Show arrows on larger screens */
@media (min-width: 721px) {
  .modal-nav-button {
    display: block;
  }

  .modal-dot-container {
    display: none;
  }
}

/* Hide arrows and show dots on mobile screens */
@media (max-width: 720px) {
  .modal-nav-button {
    display: none;
  }

  .modal-dot-container {
    display: flex;
  }
}