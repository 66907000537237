.content-hotdeal {
  padding: 0 1rem;
  /* Add padding to the content */
}

.bodyTop-container {
  width: 100%;
  height: 100%;
}

.Header h1 {
  margin-bottom: 4vh;
  display: block;
}

.Header-Frame {
  margin-bottom: 5vh;
}

.hotdeal-ofday h2 {
  display: inline-flex;
  align-items: center;
}


.hotdeal-ofday {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.BoatHelm-Tophotdeal {
  margin-top: 3vh;
  width: 10vh;

}

.BoatHelm-hotdeal {
  height: 100%;
  width: 5vh;
  margin: 0 2vh;
}

.card-container {
  display: flex;
  justify-content: center;
}

.image-container-hotdeal {
  width: 100%;

  .hotDeal-OFF {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    background-color: #151B47;
    height: 5vh;
    // width: 25vh;
    top: 2vh;
    right: 0;
    border-radius: 50px 0px 0 50px;
    padding: 0 1vh;
  }
}

.star-hotDeal {
  height: 60%;
}

.hotDeal-OFF p {
  color: white;
  font-size: 2vh;
  height: 20%;
}

.card-hotdeal {
  position: relative;
  width: calc(100% - 20px); // Adjust as needed with margins
  margin: 0 10px; // Adjust as needed
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
  margin-bottom: 30px;

  &:hover {
    .hover-rope {
      visibility: visible;
    }
  }
}

.hover-rope {
  height: 7vh;
  visibility: hidden;
  left: 0;
}

.carousel-image-hotdeal {
  width: 100%;
  height: 30vh;
  object-fit: cover;

}


.content-hotdeal h3 {
  text-align: left;
  padding-left: 0;
}

.location {
  display: flex;
  align-items: baseline;
}

.location img {
  width: 1vh;
  margin-right: 1%;
}

.location p {
  color: gray;
}

.vaildDate {
  display: flex;
}

.vaildDate h3 {
  font-size: 16px;
  margin-right: 1vh;
}

.vaildDate p {
  font-size: 16px;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-price {
  display: flex;
  align-items: center;
  justify-content: end;

}

.price h3 {
  font-size: 14px;
  margin: 1vh;
  font: 1em sans-serif;
}

.price p {
  color: red;
  margin-bottom: 1vh;
  /* Remove default margin */
  white-space: nowrap;
  /* Prevent line break */
  overflow: hidden;
  /* Hide overflow */
  font: 2em sans-serif;

}

.content p {
  color: gray;
  font-weight: 300;
}

.thumbnail-button {
  color: #fff;
  border: none;
  cursor: pointer;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.province-hot {
  margin-right: '10px';
  margin-left: '10px';
}

.selected {
  display: inline-block;
  /* Make the parent element's width fit its content */
  position: relative;
  /* Ensure relative positioning for the parent element */
}

.selected::after {
  content: '';
  /* Create a pseudo-element */
  position: absolute;
  /* Position the pseudo-element relative to its parent */
  left: 0;
  /* Start the line from the left edge of the parent element */
  bottom: -2px;
  /* Adjust the position to align with the bottom of the text */
  width: 100%;
  /* Make the line as wide as the parent element */
  border-bottom: 2px solid;
  /* Add a solid underline */
}

.province-hot {
  margin: 0 15px;
}


.carousel-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.carousel-container::before,
.carousel-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  pointer-events: none;
  z-index: 1;
}

.carousel-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.carousel-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.carousel-container .carousel-item {
  position: relative;
  z-index: 0;
  /* Ensure items are behind the gradient overlay */
}

/* Adjustments for smaller screens */
@media (max-width: 720px) {
  .card-hotdeal {
    width: 90%;
  }

  .carousel-image-hotdeal {
    height: 20vh;
    /* Adjust image height for smaller screens */
  }

  .hotdeal-ofday {
    flex-direction: column;
    align-items: center;
    display: inline-block;
  }

  .hotdeal-ofday h2 {
    font-size: 24px;
    align-items: center;
  }


  .star-hotDeal {
    height: 50%;
    margin-right: 1vh;
  }

  .hotDeal-OFF p {
    color: white;
    font-size: 1.2vh;
    margin-top: auto;
  }

  .content-hotdeal h3 {
    padding-left: 0;
    font-size: 14px;
    /* Adjust font size */
  }

  .location {
    padding-left: 0;
  }

  .location p {
    font-size: 10px;
    /* Adjust font size */
  }

  .content-price {
    display: flex;
    align-items: left;
    justify-content: left;
  }

  .price h3 {
    font: 0.7em sans-serif;
    margin-bottom: 2vh;
  }

  .price p {
    color: red;
    margin-bottom: 1vh;
    /* Remove default margin */
    white-space: nowrap;
    /* Prevent line break */
    overflow: hidden;
    /* Hide overflow */
    font: 1em sans-serif;

  }

  .hover-rope {
    height: 5vh;
    visibility: hidden;
    left: 0;
  }

  .vaildDate {
    display: flex;
    justify-content: space-between;
  }

  .vaildDate h3 {
    font-size: 12px;
    margin-right: 1vh;
  }

  .vaildDate p {
    font-size: 10px;
  }

  .BoatHelm-Tophotdeal-hotdeal {
    height: 50%;
    width: 3vh;
    margin: 0 2vh;
  }

  .province-hot {
    font-size: 12px;
    margin: 0 5px;
  }

  .BoatHelm-hotdeal {
    height: 100%;
    width: 3vh;
    margin: 0 2vh;
  }
}