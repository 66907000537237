.cardBH {
  margin: 10px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 8px;
}

.cardBH img {
  object-fit: cover;
  height: 100%;
  padding-left: 10px;
}

.cardBH-body {
  padding: 10px;
}

.cardBH-title {
  font-size: 18px !important;
  font-weight: bold !important;
}

.cardBH-text {
  margin-bottom: 10px;
  color: #555;
  font-size: 14px;
  text-align: left;
}

.cardBH-link {
  color: #007bff;
  text-decoration: none;
  font-size: 11px;
  text-align: left;
}

.col-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.date-size {
  font-size: 12px;
  margin-left: 6px;
}

.status-message {
  position: absolute;
  top: -15px;
  right: 10px;
  background-color: white;
  color: #555;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  font-weight: bold;
  z-index: 1;
}

.book-now-button {
  background-color: #28a745;
  /* Green color */
  color: #fff;
  /* White text color */
  padding: 10px 20px;
  /* Padding */
  border: none;
  /* No border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  font-size: 14px;
  /* Font size */
}

.book-now-button:hover {
  background-color: #218838;
  /* Darker green color on hover */
}

.status-col {
  justify-content: end;
}

.yes-button {
  margin-left: 10px;
  background-color: #143456 !important;
  border-radius: 12px !important;
  width: 100px;
}

.yes-button:hover {
  background-color: #0058fc !important;
}

.no-button {
  margin-left: 10px;
  border-radius: 12px !important;
  width: 100px;
}

.day-text-bh{
  font-size: 18px !important;
}

.dropdown-items:hover {
  background-color: #0063c0 !important;
}

.dropdown-items{
  background-color: #00305c !important;
  width: 60px;
  border-radius: 24px !important;
}

.circular-pagination .page-item .page-link {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.circular-pagination .page-item.active .page-link {
  background-color: #00305c;
  color: white;
  border-color: #00305c;
  font-weight: bold;
}
