.bodyTop-container {
  width: 100%;
  height: 100%;
}

.carousel-item-spacing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container-a {
  width: 100%; // Ensure the container takes up full width
  display: flex;
  justify-content: center;
  align-items: center;
}

.Marina-container {
  width: 125%;
}

.Header-TD {
  margin-bottom: 5vh;
  font-size: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.expore-text {
  font-size: 54px;
}

.top-destinations {
  margin-bottom: 5vh;
  display: block;
  font-size: 42px;
}

.content {
  margin-bottom: 15px; // Adjust the margin as needed
}

// Additional styling for the carousel container if needed
.BoatHelm-1 {
  width: 10vh;
}

.card-container {
  display: flex;
  justify-content: center;
}

.rope-Topdes {
  visibility: hidden;
  height: 5vh;
  bottom: 50%;
  position: absolute;
  transform: rotate(-10deg);
}

.card {
  // max-width: 15vh;
  max-width: calc(100% - 20px); // Adjust as needed with margins
  margin: 0 10px; // Adjust as needed
  text-align: center;
  border: none;

  // cursor: pointer;
  &:hover {
    .rope-Topdes {
      visibility: visible;
    }
  }
}

.image-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.carousel-image {
  max-width: 15dvh;
  height: auto;
  border-radius: 30%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.content p {
  color: gray;
}

.thumbnail-button {
  /* Styles for the previous and next buttons */
  color: #fff;
  border: none;
  cursor: pointer;
  /* Add any additional styles as needed */
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  /* position: relative; */
}

.custom-button-group {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.headline {
  color: #151b47;
  margin-top: 1vh;
}

// .carousel-container-a {
//   position: relative;
//   width: 80%;
//   margin: 0 auto;
// }

// .carousel-container::before,
// .carousel-container::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   width: 10%;
//   pointer-events: none;
//   z-index: 1;
// }

// .carousel-container::before {
//   left: 0;
//   background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
// }

// .carousel-container::after {
//   right: 0;
//   background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
// }

// .carousel-container .carousel-item {
//   position: relative;
//   z-index: 0;
//   /* Ensure items are behind the gradient overlay */
// }

@media screen and (max-width: 600px) {
  .Header-TD {
    margin-bottom: 5vh;
    font-size: 30px;
  }

  .Header h2 {
    display: block;
    font-size: 24px;
  }

  .headline {
    font-size: 16px;
  }

  .SubHeadline {
    font-size: 12px;
  }

}