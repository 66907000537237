* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer-container {
  max-width: 80%;
  /* background-color:#2B9AFB; */
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.row>* {
  flex-shrink: 0;
  width: 20%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

ul {
  color: #02104b;
}

.footer {
  height: auto;
  background-color: #02104b;
  margin-top: 100px;
  padding: 20px 0;
  text-align: left;
}

.footer-col {
  // width: 20%;
  // padding: 0 10px;
  align-items: flex-start;
}

.footer h3 {
  font-size: 22px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0 0 10px 0;
  font-weight: 800;
  position: relative;
}

.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 800;
  position: relative;
  text-align: left;
}

.footer-col h3 {
  font-size: 22px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 800;
  position: relative;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  display: block;
  transition: all 0.3s ease;
  text-align: left; // Align text to the left
}

.footer-col .social-links {
  display: flex;
  flex-direction: column;
}

.footer-col .social-links p {
  font-family: "Roboto";
  /* font-style: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  /* padding: 5px; */
}

.footline {
  background-color: #050c2e;
  height: 50px;
  display: flex;
  justify-content: space-between;
  /* Distribute space between columns */
  align-items: center;
  /* Center vertically */
  padding-left: 20px;
  /* Adjust as needed */
  padding-right: 20px;
  /* Adjust as needed */

}

.footline p {
  display: flex;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  margin: 5px 5px;
}

.Img-icon {
  height: 20px;
  width: auto;
  margin: 5px 5px;
}

@media (max-width: 720px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
    text-align: left;
  }

  .Follow-text {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    padding-left: 10px;
  }

  .Img-icon {
    height: 20px;
    width: auto;
    margin: 5px 10px;
  }

  .footline {
    height: auto;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
    justify-content: center;
    /* Center vertically */
    padding: 10px 20px;
    text-align: center;
    /* Center text */

  }

  .footline p {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}


.box {
  align-content: flex-start;
  background: #0669c0;
  display: flex;
  height: 100px;
  color: #ffffff;
  text-align: center;
  margin-top: -122px;
  width: 70%;
  margin: 0 auto;
  margin-top: -122px;
}

.txt {
  margin-top: 34px;
  margin-left: 75px;
}

.from {
  margin-left: 100px;
}