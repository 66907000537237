.yacht-svg {
  width: 85px;
  /* Original width */
  height: 145px;
  /* Original height */
}



@media (max-width: 720px) {
  .yacht-svg {
    width: 60px;
    /* Half the original width */
    height: 100px;
    /* Half the original height */
  }


}