.ImgProvinces {
  width: 30vh;
  height: 20vh;
}
.provinceText {
  color: white; /* White text color */
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vh;
  height: 30%;
  background: rgba(0, 0, 0, 0.4); /* Black background with 40% transparency */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}