.Filter-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh;
    width: 100%;
    margin-bottom: 5vh;
    border-bottom: 2px solid #151B47;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Add box-shadow property for a subtle shadow */
  }

#province-dropdown,
#island-dropdown,
#buttonBooking {
    background-color: #151B47; /* Set background color */
    border-radius: 50px; /* Set border radius */
}